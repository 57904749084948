import React from 'react';
import Layout from '../components/layout';
import DownloadResume from '../files/Willy_Ahmad_Syafiq_-_Product_Designer.pdf'
import DownloadIcon from '../images/download.svg'

const Resume = () => (
    <Layout>
        <div className="resume">
            <div className="columns is-centered">
                <div className="column is-10">
                    <div className="page-title-wrap">
                        <span className="title-decoratiion"></span>
                        <h1 className="general-page-title">Resume</h1>
                    </div>
                </div>
            </div>
            <div className="columns is-multiline is-centered is-gapless margin-top-2 is-hidden-mobile">
                <div className="column is-6">
                    <h2 className="main-heading">Willy Ahmad Syafiq</h2>
                    {/* <p className="contact-resume">willy.syafiq@gmail.com</p>
                    <p className="contact-resume">(+62)896 3635 4693</p> */}
                </div>
                <div className="column is-3">
                    <a href={DownloadResume} className="button download-resume is-pulled-right" download>
                        <span className="icon">
                            <img src={DownloadIcon} alt="Download Icon" className="icon" />
                        </span>
                        <span>Download PDF</span>
                    </a>
                </div>
            </div>
            <div className="columns is-multiline is-centered margin-top-0">
                <div className="column is-3 block-padding is-flex">
                    <div className="block-skill">
                        <h2 className="skill-heading">Design</h2>
                        <ul>
                            <li>User Research</li>
                            <li>Persona Development</li>
                            <li>Information Architecture</li>
                            <li>Competitive Analysis</li>
                            <li>User Journey</li>
                            <li>Wireframing</li>
                            <li>Heuristic Analysis</li>
                            <li>Usability Testing</li>
                            <li>Design System</li>
                            <li>Prototyping</li>
                        </ul>
                    </div>
                </div>
                <div className="column is-3 block-padding is-flex">
                    <div className="block-skill">
                        <h2 className="skill-heading">Tools</h2>
                        <ul>
                            <li>Sketch</li>
                            <li>Figma</li>
                            <li>Abstract</li>
                            <li>Zeplin</li>
                            <li>InVision</li>
                            <li>Marvel</li>
                            <li>Miro</li>
                            <li>Photoshop</li>
                            <li>Illustrator</li>
                            <li>Jira</li>
                        </ul>
                    </div>
                </div>
                <div className="column is-3 block-padding is-flex">
                    <div className="block-skill">
                        <h2 className="skill-heading">Tech</h2>
                        <ul>
                            <li>React</li>
                            <li>Gatsby</li>
                            <li>Wordpress</li>
                            <li>HTML</li>
                            <li>SCSS/CSS</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="columns is-centered">
                <div className="column is-6">
                    <div className="work-experience">
                        <h3 className="company-name">Vision+ - UI/UX Section Head</h3>
                        <p className="duration">Sep 2020 - Present</p>
                        <p>In charge of feature development, research, and design system development. Mentoring juniors while working closely with data team and engineers to measure design and technical performance.</p>

                        <p>Revolutionized the workflow in an old school corporate environment to be agile using modern tools and collaborative methods. Worked on customer-facing products on various platforms ranging from Mobile App, Web, up to Smart TV app.</p>
                    </div>
                    <div className="work-experience">
                        <h3 className="company-name">Gredu - Sr. UI/UX Designer</h3>
                        <p className="duration">Nov 2019 - Mar 2020</p>
                        <p>In charge of defining user journey, user flow, and wireframe of upcoming features or brand new products. Led Design System development that serves more than 5 products including Web & Mobile Apps for tech-savvy students, parents, up to elder generation teachers.</p>
                    </div>
                    <div className="work-experience">
                        <h3 className="company-name">Volantis - UI Designer</h3>
                        <p className="duration">Oct 2018 - Jul 2019</p>
                        <p>Developed Design System starter for Volantis AI platform with more than 9 Front-end Developers while working on product development and client-based projects as well.</p>
                    </div>
                    <div className="work-experience">
                        <h3 className="company-name">MarkPlus, Inc. - Web Designer</h3>
                        <p className="duration">Jun 2016 - Oct 2018</p>
                        <p>Designed web and mobile apps for internal and client-based projects starting from initial research, mockup design, front-end, up to the SEO works. Managed e-commerce sites and some email marketing projects.</p>
                    </div>
                    <div className="work-experience">
                        <h3 className="company-name">Tirta Varia Intipratama - Web Designer</h3>
                        <p className="duration">Jan 2016 - May 2016</p>
                        <p>Developed internal web and mobile apps for finance, HR, operation, and other department needs. Mostly do the high-fidelity mockup and slicing works.</p>
                    </div>
                    <div className="work-experience">
                        <hr className="resume-divider" />
                        <h3 className="company-name">Education</h3>
                        <p className="margin-bot-0">Bachelor of Informatics Engineering</p>
                        <p>Universitas Komputer Indonesia, Bandung</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default Resume;
